import { combineReducers } from 'redux';
import { ALL, LOAD_MORE, IS_LOADING } from '@/actions/questionnaire/types';
const initialState = {
    all: [],
    csvQuestions: [],
    pagination: {
        count: 0,
        current_page: 1,
        per_page: 0,
        total_pages: 0,
        total: 0
    },
    isLoading: false
}
function questionnaires(state = initialState, action) {
    if (action.type === ALL) {
        let from = (action.pagination.current_page - 1) * action.pagination.per_page + 1;
        let to = from + action.pagination.count - 1;
        return Object.assign({}, state, {
            all: action.all,
            pagination: {
                ...action.pagination,
                from,
                to
            }
        });
    }
    if (action.type === LOAD_MORE) {
        return {
            ...state,
            all: state.all.concat(action.all.map(member => member)),
            pagination: action.pagination
        }
    }
    if (action.type === IS_LOADING) {
        return {
            ...state,
            isLoading: action.isLoading
        }
    }
    return state
}
const QuestionnaireReducer = combineReducers({ questionnaires });
export default QuestionnaireReducer;
