/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Axios will now been used to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Axios from 'axios';
import Validator from '../utils/Validator'
import history from '@/utils/history';
import * as qs from 'qs'
import { loadProgressBar } from 'axios-progress-bar'
import Swal from "sweetalert2";
import I18n from '@/utils/I18n';

Axios.defaults.baseURL = process.env.REACT_APP_API_LOCATION;
Axios.defaults.headers.common['Accept'] = 'application/json';

// Request interceptor
Axios.interceptors.request.use(request => {

    // const locale = store.getters['lang/locale'];
    //
    // if (locale) {
    // request.headers.common['Accept-Language'] = 'en';
    // }

    const methods = ['post', 'put', 'patch', 'options'];

    // request.headers['X-Socket-Id'] = Echo.socketId()

    request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    request.headers['X-MMS-Locale'] = I18n.getCurrentLocale();

    if (methods.includes(request.method) && request.data && !(request.data instanceof FormData) && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        request.data = qs.stringify(request.data);
    }
    loadProgressBar()
    return request;
});

// Response interceptor
Axios.interceptors.response.use((response) => response, (error) => {
    const { status } = error.response;
    if (status >= 500) {
        console.log('Error : ', status);
    }

    if (status === 401) {
        console.log('Error : ', status);
        history.push('/');
    }

    if (status === 403) {
        console.log('Error : ', status);
        if (error.response && error.response.data) {
            Swal.fire({
                position: 'top-end',
                type: 'error',
                title: error.response.data.error,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    if (status === 422) {
        Validator.fill(error.response.data.error)
    }

    return Promise.reject(error);
});

export default Axios;
