import { combineReducers } from 'redux'
import { ALL, CREATED, REPLIES, UPDATED, BADGE } from '@/actions/messages/types'

const initialState = {
    all: [],
    pagination: {
        count: 0,
        current_page: 1,
        per_page: 0,
        total_pages: 0,
        total: 0
    },
    isLoading: false,
    detail: {
        replies: []
    }
}

/**
 * Message state
 * @param state
 * @param action
 * @returns {{all: Array, isLoading: boolean, pagination: {per_page: number, total: number, count: number, total_pages: number, current_page: number}}|({} & {all: Array, isLoading: boolean, pagination: {per_page: number, total: number, count: number, total_pages: number, current_page: number}} & {all: *, pagination: {from: number, to: number}})}
 */
function messages(state = initialState, action) {
    if (action.type === ALL) {
        let from = (action.pagination.current_page - 1) * action.pagination.per_page + 1
        let to = from + action.pagination.count - 1
        return Object.assign({}, state, {
            all: action.all,
            pagination: {
                ...action.pagination,
                from,
                to
            },
            path: action.path
        })
    }
    if (action.type === CREATED) {
        const replies = state.detail.replies
        replies.push(action.reply)
        return Object.assign({}, state, {
            detail: {
                replies
            }
        })
    }
    if (action.type === BADGE) {
        return Object.assign({}, state, {
            badge: action.badge
        })
    }
    if (action.type === REPLIES) {
        return Object.assign({}, state, {
            detail: { ...action.detail }
        })
    }
    if (action.type === UPDATED) {
        const message = action.message;
        state.all = state.all.map(item => {
            if (item.id === message.id) {
                return message
            }
            return item
        })
    }
    return state
}

const MessageReducer = combineReducers({ messages })

export default MessageReducer
