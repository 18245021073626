import React, { Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config'
import './App.scss'
import 'axios-progress-bar/dist/nprogress.css'

import "moment/locale/ja";

import history from '@/utils/history'
import { connect } from 'react-redux'
import { check, fetchAuth } from '@/actions/auth'
import Cookie from 'js-cookie'
import { LOADING } from './components/utils'

const loading = LOADING;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Auth/Login/Index'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))

class App extends Component {
  constructor (props) {
    super(props)
    const { check, fetchAuth } = props
    if (check() && !!Cookie.get('access_token')) {
      fetchAuth()
    }
  }

  render () {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
            <Route path="/" name="Dashboard" render={props => <DefaultLayout {...props}/>}/>
          </Switch>
        </React.Suspense>
      </Router>
    )
  }
}

const mapStateToProps = state => {
  const { auth } = state.getIn(['auth'])
  return { auth }
}

const mapDispatchToProps = dispatch => {
  return {
    check: () => dispatch(check()),
    fetchAuth: () => dispatch(fetchAuth()),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
