import { CLEAR, FILL, FLUSH } from './types'

/**
 * The validation mutation
 * @param response
 * @returns {{type: string, error: *}}
 * @constructor
 */
export function FillMutation (response) {
  return {
    type: FILL,
    error: response.error,
    message: response.message
  };
}

export function ClearMutation (field) {
  return {
    type: CLEAR,
    field
  };
}

export function FlushMutation () {
  return {
    type: FLUSH,
    error: {}
  };
}
