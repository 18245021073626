/*
 * General Messages
 *
 * This contains all the text for all components.
 */
import { defineMessages } from 'react-intl';

export const scope = 'general';

export default defineMessages({
    id: {
        id: `${scope}.id`,
        defaultMessage: 'ID'
    },
    stripe_customer_id: {
        id: `${scope}.stripe_customer_id`,
        defaultMessage: 'Stripe Customer ID'
    },
    appName: {
        id: `${scope}.appName`,
        defaultMessage: 'Media Member System'
    },
    none: {
        id: `${scope}.none`,
        defaultMessage: 'None'
    },
    all: {
        id: `${scope}.all`,
        defaultMessage: 'All'
    },
    group: {
        id: `${scope}.group`,
        defaultMessage: 'Group'
    },
    acquisition: {
        id: `${scope}.acquisition`,
        defaultMessage: 'Acquisition'
    },
    action: {
        id: `${scope}.action`,
        defaultMessage: "Action"
    },
    actions: {
        id: `${scope}.actions`,
        defaultMessage: "Actions"
    },
    active: {
        id: `${scope}.active`,
        defaultMessage: "Active"
    },
    add: {
        id: `${scope}.add`,
        defaultMessage: 'Add'
    },
    address: {
        id: `${scope}.address`,
        defaultMessage: 'Address'
    },
    amount: {
        id: `${scope}.amount`,
        defaultMessage: 'Amount'
    },
    announcements: {
        id: `${scope}.announcements`,
        defaultMessage: 'Announcements'
    },
    apply: {
        id: `${scope}.apply`,
        defaultMessage: 'Apply'
    },
    article: {
        id: `${scope}.article`,
        defaultMessage: 'Article'
    },
    attributes: {
        id: `${scope}.attributes`,
        defaultMessage: 'Attributes'
    },
    attachments: {
        id: `${scope}.attachments`,
        defaultMessage: 'Attachments'
    },
    attachmentSize: {
        id: `${scope}.attachmentSize`,
        defaultMessage: '{size} KB'
    },
    authorize: {
        id: `${scope}.authorize`,
        defaultMessage: 'Authorize'
    },
    back: {
        id: `${scope}.back`,
        defaultMessage: 'Back'
    },
    behavior: {
        id: `${scope}.behavior`,
        defaultMessage: 'Behavior'
    },
    cancel: {
        id: `${scope}.cancel`,
        defaultMessage: "Cancel"
    },
    categories: {
        id: `${scope}.categories`,
        defaultMessage: 'Categories'
    },
    conversion: {
        id: `${scope}.conversion`,
        defaultMessage: 'Conversion'
    },
    close: {
        id: `${scope}.close`,
        defaultMessage: 'Close'
    },
    color: {
        id: `${scope}.color`,
        defaultMessage: "Color"
    },
    compose: {
        id: `${scope}.compose`,
        defaultMessage: "Compose"
    },
    create: {
        id: `${scope}.create`,
        defaultMessage: 'Create'
    },
    empty: {
        id: `${scope}.empty`,
        defaultMessage: 'Empty'
    },
    element: {
        id: `${scope}.element`,
        defaultMessage: 'Element'
    },
    symbolCurrency: {
        id: `${scope}.symbolCurrency`,
        defaultMessage: '¥'
    },
    dashboard: {
        id: `${scope}.dashboard`,
        defaultMessage: 'Dashboard'
    },
    day: {
        id: `${scope}.day`,
        defaultMessage: 'Day'
    },
    delete: {
        id: `${scope}.delete`,
        defaultMessage: 'Delete'
    },
    description: {
        id: `${scope}.description`,
        defaultMessage: 'Description'
    },
    detail: {
        id: `${scope}.detail`,
        defaultMessage: 'Detail'
    },
    discard: {
        id: `${scope}.discard`,
        defaultMessage: 'Discard'
    },
    draft: {
        id: `${scope}.draft`,
        defaultMessage: 'Draft'
    },
    download: {
        id: `${scope}.download`,
        defaultMessage: 'Download'
    },
    edit: {
        id: `${scope}.edit`,
        defaultMessage: 'Edit'
    },
    email: {
        id: `${scope}.email`,
        defaultMessage: 'Email'
    },
    event: {
        id: `${scope}.event`,
        defaultMessage: 'Event'
    },
    first: {
        id: `${scope}.first`,
        defaultMessage: 'First'
    },
    goods: {
        id: `${scope}.goods`,
        defaultMessage: 'Goods'
    },
    home: {
        id: `${scope}.home`,
        defaultMessage: 'Home'
    },
    hourly: {
        id: `${scope}.hourly`,
        defaultMessage: 'Hourly'
    },
    important: {
        id: `${scope}.important`,
        defaultMessage: 'Important'
    },
    inbox: {
        id: `${scope}.inbox`,
        defaultMessage: 'Inbox'
    },
    interval: {
        id: `${scope}.interval`,
        defaultMessage: 'Interval'
    },
    last: {
        id: `${scope}.last`,
        defaultMessage: 'Last'
    },
    label: {
        id: `${scope}.label`,
        defaultMessage: 'Label'
    },
    slug: {
        id: `${scope}.slug`,
        defaultMessage: 'Slug'
    },
    loading: {
        id: `${scope}.loading`,
        defaultMessage: 'Loading...'
    },
    logout: {
        id: `${scope}.logout`,
        defaultMessage: 'Logout'
    },
    member: {
        id: `${scope}.member`,
        defaultMessage: 'Member'
    },
    members: {
        id: `${scope}.members`,
        defaultMessage: 'Members'
    },
    month: {
        id: `${scope}.month`,
        defaultMessage: 'Month'
    },
    name: {
        id: `${scope}.name`,
        defaultMessage: 'Name'
    },
    accountType: {
        id: `${scope}.accountType`,
        defaultMessage: 'Account Type'
    },
    ethAddress: {
        id: `${scope}.ethAddress`,
        defaultMessage: 'Wallet Address'
    },
    cxenseName: {
        id: `${scope}.cxenseName`,
        defaultMessage: 'CXense Name'
    },
    cuenoteAddressBookId: {
        id: `${scope}.cuenoteAddressBookId`,
        defaultMessage: 'Cuenote Address Book ID'
    },
    new: {
        id: `${scope}.new`,
        defaultMessage: 'New'
    },
    no: {
        id: `${scope}.no`,
        defaultMessage: 'No'
    },
    other: {
        id: `${scope}.other`,
        defaultMessage: 'Other'
    },
    page: {
        id: `${scope}.page`,
        defaultMessage: 'Page'
    },
    username: {
        id: `${scope}.username`,
        defaultMessage: 'Username'
    },
    password: {
        id: `${scope}.password`,
        defaultMessage: 'Password'
    },
    password_confirmation: {
        id: `${scope}.password_confirmation`,
        defaultMessage: 'Password confirmation'
    },
    paid: {
        id: `${scope}.paid`,
        defaultMessage: 'Paid'
    },
    percentage: {
        id: `${scope}.percentage`,
        defaultMessage: '%'
    },
    placeholder: {
        id: `${scope}.placeholder`,
        defaultMessage: 'Placeholder'
    },
    phone: {
        id: `${scope}.phone`,
        defaultMessage: 'Phone'
    },
    price: {
        id: `${scope}.price`,
        defaultMessage: 'Price'
    },
    pricing: {
        id: `${scope}.pricing`,
        defaultMessage: 'Pricing'
    },
    product: {
        id: `${scope}.product`,
        defaultMessage: 'Product'
    },
    products: {
        id: `${scope}.products`,
        defaultMessage: 'Products'
    },
    profile: {
        id: `${scope}.profile`,
        defaultMessage: 'Profile'
    },
    publish: {
        id: `${scope}.publish`,
        defaultMessage: 'Publish'
    },
    quantity: {
        id: `${scope}.quantity`,
        defaultMessage: 'Quantity'
    },
    register: {
        id: `${scope}.register`,
        defaultMessage: 'Register'
    },
    registrationDate: {
        id: `${scope}.registrationDate`,
        defaultMessage: 'Registration Datetime'
    },
    registrationDateOrderASC: {
        id: `${scope}.registrationDateOrderASC`,
        defaultMessage: 'Registration Datetime Order by ASC'
    },
    registrationDateOrderDESC: {
        id: `${scope}.registrationDateOrderDESC`,
        defaultMessage: 'Registration Datetime Order by DESC'
    },
    updatedDate: {
        id: `${scope}.updatedDate`,
        defaultMessage: 'Profile Updated Datetime'
    },
    updatedDateOrderASC: {
        id: `${scope}.updatedDateOrderASC`,
        defaultMessage: 'Profile Updated Datetime Order by ASC'
    },
    updatedDateOrderDESC: {
        id: `${scope}.updatedDateOrderDESC`,
        defaultMessage: 'Profile Updated Datetime Order by DESC'
    },
    restore: {
        id: `${scope}.restore`,
        defaultMessage: 'Restore'
    },
    required: {
        id: `${scope}.required`,
        defaultMessage: 'Required'
    },
    role: {
        id: `${scope}.role`,
        defaultMessage: 'Role'
    },
    sales: {
        id: `${scope}.sales`,
        defaultMessage: 'Sales'
    },
    send: {
        id: `${scope}.send`,
        defaultMessage: 'Send'
    },
    sent: {
        id: `${scope}.sent`,
        defaultMessage: 'Sent'
    },
    sessions: {
        id: `${scope}.sessions`,
        defaultMessage: 'Sessions'
    },
    search: {
        id: `${scope}.search`,
        defaultMessage: "Search..."
    },
    show: {
        id: `${scope}.show`,
        defaultMessage: 'Show'
    },
    site: {
        id: `${scope}.site`,
        defaultMessage: 'Site'
    },
    domain: {
        id: `${scope}.domain`,
        defaultMessage: 'Domain'
    },
    extraDomains: {
        id: `${scope}.extradomains`,
        defaultMessage: 'Extra Domains'
    },
    sites: {
        id: `${scope}.sites`,
        defaultMessage: 'Sites'
    },
    sku: {
        id: `${scope}.sku`,
        defaultMessage: 'SKU'
    },
    skus: {
        id: `${scope}.skus`,
        defaultMessage: 'SKU(s)'
    },
    systems: {
        id: `${scope}.systems`,
        defaultMessage: 'Systems'
    },
    socialmedia: {
        id: `${scope}.socialmedia`,
        defaultMessage: 'Social media'
    },
    sources: {
        id: `${scope}.sources`,
        defaultMessage: 'Sources'
    },
    status: {
        id: `${scope}.status`,
        defaultMessage: 'Status'
    },
    start: {
        id: `${scope}.start`,
        defaultMessage: 'Start'
    },
    subject: {
        id: `${scope}.subject`,
        defaultMessage: 'Subject'
    },
    submit: {
        id: `${scope}.submit`,
        defaultMessage: "Submit"
    },
    subscriptions: {
        id: `${scope}.subscriptions`,
        defaultMessage: 'Subscriptions'
    },
    subtitle: {
        id: `${scope}.subtitle`,
        defaultMessage: "Subtitle"
    },
    subtype: {
        id: `${scope}.subtype`,
        defaultMessage: "Subtype"
    },
    tag: {
        id: `${scope}.tag`,
        defaultMessage: "Tags"
    },
    title: {
        id: `${scope}.title`,
        defaultMessage: "Title"
    },
    to: {
        id: `${scope}.to`,
        defaultMessage: 'To'
    },
    total: {
        id: `${scope}.total`,
        defaultMessage: "Total"
    },
    trash: {
        id: `${scope}.trash`,
        defaultMessage: 'Trash'
    },
    type: {
        id: `${scope}.type`,
        defaultMessage: 'Type'
    },
    update: {
        id: `${scope}.update`,
        defaultMessage: 'Update'
    },
    users: {
        id: `${scope}.users`,
        defaultMessage: 'Users'
    },
    unpaid: {
        id: `${scope}.unpaid`,
        defaultMessage: 'Unpaid'
    },
    unpublish: {
        id: `${scope}.unpublish`,
        defaultMessage: 'Unpublish'
    },
    values: {
        id: `${scope}.values`,
        defaultMessage: 'Values'
    },
    week: {
        id: `${scope}.week`,
        defaultMessage: 'Week'
    },
    yes: {
        id: `${scope}.yes`,
        defaultMessage: 'Yes'
    },
    year: {
        id: `${scope}.year`,
        defaultMessage: 'Year'
    },
    yearInterval: {
        id: `${scope}.yearInterval`,
        defaultMessage: 'Year'
    },
    avgValue: {
        id: `${scope}.avgValue`,
        defaultMessage: 'Avg. Value'
    },
    bounceRate: {
        id: `${scope}.bounceRate`,
        defaultMessage: 'Bounce Rate'
    },
    chooseFile: {
        id: `${scope}.chooseFile`,
        defaultMessage: 'Choose File'
    },
    content: {
        id: `${scope}.content`,
        defaultMessage: 'Content'
    },
    createdAt: {
        id: `${scope}.createdAt`,
        defaultMessage: 'Created At'
    },
    expiredAt: {
        id: `${scope}.expiredAt`,
        defaultMessage: 'Expired At'
    },
    expired: {
        id: `${scope}.expired`,
        defaultMessage: 'Expired'
    },
    customFields: {
        id: `${scope}.customFields`,
        defaultMessage: 'Custom Fields'
    },
    defaultDate: {
        id: `${scope}.defaultDate`,
        defaultMessage: 'Default Date'
    },
    defaultValue: {
        id: `${scope}.defaultValue`,
        defaultMessage: 'Default Value'
    },
    explanation: {
        id: `${scope}.explanation`,
        defaultMessage: 'Explaination'
    },
    confirmTitle: {
        id: `${scope}.confirmTitle`,
        defaultMessage: 'Are you sure?'
    },
    confirmCancel: {
        id: `${scope}.confirmCancel`,
        defaultMessage: 'No, cancel!'
    },
    deleted: {
        id: `${scope}.deleted`,
        defaultMessage: 'Deleted'
    },
    deleteCancel: {
        id: `${scope}.deleteCancel`,
        defaultMessage: 'No, cancel!'
    },
    deleteConfirm: {
        id: `${scope}.deleteConfirm`,
        defaultMessage: 'Yes, delete it!'
    },
    deleteForever: {
        id: `${scope}.deleteForever`,
        defaultMessage: 'Delete forever'
    },
    deleteTitle: {
        id: `${scope}.deleteTitle`,
        defaultMessage: 'Are you sure?'
    },
    deleteText: {
        id: `${scope}.deleteText`,
        defaultMessage: "You won't be able to revert this!"
    },
    enterName: {
        id: `${scope}.enterName`,
        defaultMessage: "Enter name"
    },
    enterSubject: {
        id: `${scope}.enterSubject`,
        defaultMessage: "Enter Subject"
    },
    enterEmail: {
        id: `${scope}.enterEmail`,
        defaultMessage: "Enter email"
    },
    enterPassword: {
        id: `${scope}.enterPassword`,
        defaultMessage: "Enter password"
    },
    enterPasswordConfirmation: {
        id: `${scope}.enterPasswordConfirmation`,
        defaultMessage: "Enter password confirmation"
    },
    softDeleteText: {
        id: `${scope}.softDeleteText`,
        defaultMessage: "You want to delete this!"
    },
    restoreTitle: {
        id: `${scope}.restoreTitle`,
        defaultMessage: 'Are you sure?'
    },
    restoreText: {
        id: `${scope}.restoreText`,
        defaultMessage: "You want to restore this!"
    },
    restoreCancel: {
        id: `${scope}.restoreCancel`,
        defaultMessage: 'No, cancel!'
    },
    restoreConfirm: {
        id: `${scope}.restoreConfirm`,
        defaultMessage: 'Yes, restore it!'
    },
    editProfile: {
        id: `${scope}.editProfile`,
        defaultMessage: 'Edit Profile'
    },
    eventFilter: {
        id: `${scope}.eventFilter`,
        defaultMessage: 'Event {filterName}',
        values: {
            filterName: null
        }
    },
    eventsSessionEvent: {
        id: `${scope}.eventsSessionEvent`,
        defaultMessage: 'Events / Session with Event'
    },
    eventTracking: {
        id: `${scope}.eventTracking`,
        defaultMessage: 'Event Tracking'
    },
    eventValue: {
        id: `${scope}.eventValue`,
        defaultMessage: 'Event Value'
    },
    displayName: {
        id: `${scope}.displayName`,
        defaultMessage: 'Display Name'
    },
    googleAnalytics: {
        id: `${scope}.googleAnalytics`,
        defaultMessage: 'Google Analytics'
    },
    growthRate: {
        id: `${scope}.growthRate`,
        defaultMessage: 'Growth Rate'
    },
    imageDimension: {
        id: `${scope}.imageDimension`,
        defaultMessage: 'Suggested image dimensions: {nbWidth} x {nbHeight} pixels.'
    },
    invalidDate: {
        id: `${scope}.invalidDate`,
        defaultMessage: '{date} is invalid date'
    },
    invalidYearRange: {
        id: `${scope}.invalidYearRange`,
        defaultMessage: 'MAX Year must be greater than MIN Year'
    },
    invalidYearRangeValue: {
        id: `${scope}.invalidYearRangeValue`,
        defaultMessage: 'Year range must start from {min} to {max}'
    },
    maxYear: {
        id: `${scope}.maxYear`,
        defaultMessage: 'Max Year'
    },
    minYear: {
        id: `${scope}.minYear`,
        defaultMessage: 'Min Year'
    },
    newUsers: {
        id: `${scope}.newUsers`,
        defaultMessage: 'New Users'
    },
    newVisitor: {
        id: `${scope}.newVisitor`,
        defaultMessage: 'New Visitor'
    },
    nickname: {
        id: `${scope}.nickname`,
        defaultMessage: 'Nickname'
    },
    cxenseNickname: {
        id: `${scope}.cxenseNickname`,
        defaultMessage: 'CXense Nickname'
    },
    orderDate: {
        id: `${scope}.orderDate`,
        defaultMessage: 'Order Date'
    },
    lastUpdate: {
        id: `${scope}.lastUpdate`,
        defaultMessage: 'Last Update'
    },
    orderNumber: {
        id: `${scope}.orderNumber`,
        defaultMessage: 'Order Number'
    },
    pagesSession: {
        id: `${scope}.pagesSession`,
        defaultMessage: 'Pages / Session'
    },
    pageViews: {
        id: `${scope}.pageViews`,
        defaultMessage: 'Page Views'
    },
    paymentMethod: {
        id: `${scope}.paymentMethod`,
        defaultMessage: 'Payment Method'
    },
    perPage: {
        id: `${scope}.perPage`,
        defaultMessage: 'Per Page:'
    },
    productName: {
        id: `${scope}.productName`,
        defaultMessage: 'Product Name'
    },
    returnVisitor: {
        id: `${scope}.returnVisitor`,
        defaultMessage: 'Returning Visitor'
    },
    save: {
        id: `${scope}.save`,
        defaultMessage: 'Save'
    },
    schedulesAt: {
        id: `${scope}.schedulesAt`,
        defaultMessage: 'Scheduled At'
    },
    scenario: {
        id: `${scope}.scenario`,
        defaultMessage: 'Scenario'
    },
    segment: {
        id: `${scope}.segment`,
        defaultMessage: 'Segment'
    },
    select: {
        id: `${scope}.select`,
        defaultMessage: '─ Select ─'
    },
    numberSelected: {
        id: `${scope}.numberSelected`,
        defaultMessage: '{number_of_selected} Selected'
    },
    selectCategory: {
        id: `${scope}.selectCategory`,
        defaultMessage: '─ Select categroy ─'
    },
    selectMember: {
        id: `${scope}.selectMember`,
        defaultMessage: '─ Select member ─'
    },
    selectGroup: {
        id: `${scope}.selectGroup`,
        defaultMessage: '─ Select group ─'
    },
    selectSite: {
        id: `${scope}.selectSite`,
        defaultMessage: '─ Select site ─'
    },
    selectTemplate: {
        id: `${scope}.selectTemplate`,
        defaultMessage: '─ Select template ─'
    },
    selectQuestionnaire: {
        id: `${scope}.selectQuestionnaire`,
        defaultMessage: '─ Select Questionnaire ─'
    },
    selectType: {
        id: `${scope}.selectType`,
        defaultMessage: '─ Select type ─'
    },
    selectSubtype: {
        id: `${scope}.selectSubtype`,
        defaultMessage: '─ Select subtype ─'
    },
    selectTags: {
        id: `${scope}.selectTags`,
        defaultMessage: '─ Select tags ─'
    },
    selectUser: {
        id: `${scope}.selectUser`,
        defaultMessage: '─ Select user ─'
    },
    selectProduct: {
        id: `${scope}.selectProduct`,
        defaultMessage: '─ Select product ─'
    },
    selectPlan: {
        id: `${scope}.selectPlan`,
        defaultMessage: '─ Select plan ─'
    },
    selectOrderBy: {
        id: `${scope}.selectOrderBy`,
        defaultMessage: '─ Sort ─'
    },
    selectSNS: {
        id: `${scope}.selectSNS`,
        defaultMessage: 'Select SNS'
    },
    selectParent: {
        id: `${scope}.selectParent`,
        defaultMessage: '─ Select Parent Field ─'
    },
    sessionsEvent: {
        id: `${scope}.sessionsEvent`,
        defaultMessage: 'Sessions with Event'
    },
    template: {
        id: `${scope}.template`,
        defaultMessage: 'template'
    },
    topEvents: {
        id: `${scope}.topEvents`,
        defaultMessage: 'Top Events'
    },
    totalEvents: {
        id: `${scope}.totalEvents`,
        defaultMessage: 'Total Events'
    },
    uniqueEvents: {
        id: `${scope}.uniqueEvents`,
        defaultMessage: 'Unique Events'
    },
    UniqueUsers: {
        id: `${scope}.UniqueUsers`,
        defaultMessage: 'Unique Users'
    },
    unitPrice: {
        id: `${scope}.unitPrice`,
        defaultMessage: 'Unit Price'
    },
    upload: {
        id: `${scope}.upload`,
        defaultMessage: 'Upload'
    },
    uploadPhoto: {
        id: `${scope}.uploadPhoto`,
        defaultMessage: 'Upload Photo'
    },
    uploadProfile: {
        id: `${scope}.uploadProfile`,
        defaultMessage: 'Upload Profile'
    },
    uploadPCLogo: {
        id: `${scope}.uploadPCLogo`,
        defaultMessage: 'Upload PC Logo'
    },
    uploadSPLogo: {
        id: `${scope}.uploadSPLogo`,
        defaultMessage: 'Upload SP Logo'
    },
    uploadPopupLogo: {
        id: `${scope}.uploadPopupLogo`,
        defaultMessage: 'Upload Popup Logo'
    },
    yearRange: {
        id: `${scope}.yearRange`,
        defaultMessage: 'Year Range'
    },
    avgForView: {
        id: `${scope}.avgForView`,
        defaultMessage: 'Avg for View: {gaData} {number}',
        values: {
            gaData: null,
            number: '100.00%'
        }
    },
    avgSessionDuration: {
        id: `${scope}.avgSessionDuration`,
        defaultMessage: 'Avg. Session Duration'
    },
    gaPVUU: {
        id: `${scope}.gaPVUU`,
        defaultMessage: 'PV and UU'
    },
    goalCompletions: {
        id: `${scope}.goalCompletions`,
        defaultMessage: 'Goal Completions'
    },
    failFetchData: {
        id: `${scope}.failFetchData`,
        defaultMessage: 'Failed to fetch data'
    },
    failDownloadFile: {
        id: `${scope}.failDownloadFile`,
        defaultMessage: 'File could not be downloaded'
    },
    goalConversionRate: {
        id: `${scope}.goalConversionRate`,
        defaultMessage: 'Goal Conversion Rate'
    },
    goalValue: {
        id: `${scope}.goalValue`,
        defaultMessage: 'Goal Value'
    },
    numberOfSale: {
        id: `${scope}.numberOfSale`,
        defaultMessage: 'Number Of Sale'
    },
    percentOfTotal: {
        id: `${scope}.percentOfTotal`,
        defaultMessage: '% of Total: {number}%',
        values: {
            number: '100.00'
        }
    },
    saleConversionTracking: {
        id: `${scope}.saleConversionTracking`,
        defaultMessage: 'Sale Conversion Tracking'
    },
    fileFormatNotSupported: {
        id: `${scope}.fileFormatNotSupported`,
        defaultMessage: 'This file type is not supported'
    },
    fileMaxUploadSize: {
        id: `${scope}.fileMaxUploadSize`,
        defaultMessage: 'This file exceeds the maximum upload size'
    },
    numberSessionsPerUser: {
        id: `${scope}.numberSessionsPerUser`,
        defaultMessage: 'Number of Sessions per User'
    },
    pageViewsUniqueUsers: {
        id: `${scope}.pageViewsUniqueUsers`,
        defaultMessage: 'Page Views and Unique Users'
    },
    calendarLastDay: {
        id: `${scope}.calendar.lastDay`,
        defaultMessage: '[Yesterday at] LTS'
    },
    calendarSameDay: {
        id: `${scope}.calendar.sameDay`,
        defaultMessage: '[Today at] LTS'
    },
    calendarNextDay: {
        id: `${scope}.calendar.nextDay`,
        defaultMessage: '[Tomorrow at] LTS'
    },
    calendarLastWeek: {
        id: `${scope}.calendar.lastWeek`,
        defaultMessage: '[last] dddd [at] LTS'
    },
    calendarNextWeek: {
        id: `${scope}.calendar.nextWeek`,
        defaultMessage: 'dddd [at] LTS'
    },
    calendarSameElse: {
        id: `${scope}.calendar.sameElse`,
        defaultMessage: 'MMM dddd, LTS'
    },
    mediaLibrary: {
        id: `${scope}.media.library`,
        defaultMessage: 'Media library'
    },
    deselectAll: {
        id: `${scope}.deselect.all`,
        defaultMessage: 'Deselect all'
    },
    dragDropBrowseFile: {
        id: `${scope}.drag.drop.browse.file`,
        defaultMessage: 'Drag and drop file here or Browse'
    },
    labelSelectOption: {
        id: `${scope}.labelSelectOption`,
        defaultMessage: 'Select option'
    },
    insertToEditor: {
        id: `${scope}.insert.to.editor`,
        defaultMessage: 'Insert to editor'
    },
    warnSelectFile: {
        id: `${scope}.warn.select.file`,
        defaultMessage: 'Please select a file!'
    },
    code: {
        id: `${scope}.code`,
        defaultMessage: 'Code'
    },
    version: {
        id: `${scope}.version`,
        defaultMessage: 'Version'
    },
    subscriptionsData: {
        id: `${scope}.subscriptionsData`,
        defaultMessage: 'Subscriptions Data'
    },
    switchSiteTitle: {
        id: `${scope}.switchSiteTitle`,
        defaultMessage: 'Are you sure want to switch site?'
    },
    switchSiteText: {
        id: `${scope}.switchSiteText`,
        defaultMessage: 'Custom fields data of old site could be lost'
    },
    switch: {
        id: `${scope}.switch`,
        defaultMessage: 'Switch'
    },
    memberSubscriptionsData: {
        id: `${scope}.memberSubscriptionsData`,
        defaultMessage: 'Member Data'
    },
    url: {
        id: `${scope}.url`,
        defaultMessage: 'Url'
    },
    documentDownload: {
        id: `${scope}.documentDownload`,
        defaultMessage: 'Document download'
    },
    documentDownloadForm: {
        id: `${scope}.documentDownloadForm`,
        defaultMessage: 'Document download form'
    },
    exportCSV: {
        id: `${scope}.exportCSV`,
        defaultMessage: 'Export CSV'
    },
    newUserRegister: {
        id: `${scope}.newUserRegister`,
        defaultMessage: 'New User Register'
    },
    newUserSubscribe: {
        id: `${scope}.newUserSubscribe`,
        defaultMessage: 'New User Subscribe'
    },
    form: {
        id: `${scope}.form`,
        defaultMessage: 'Form'
    },
    customForm: {
        id: `${scope}.customForm`,
        defaultMessage: 'Custom Form'
    },
    documentDlMemberData: {
        id: `${scope}.documentDlMemberData`,
        defaultMessage: 'Document DL Member Data'
    },
    campaign: {
        id: `${scope}.campaign`,
        defaultMessage: 'Campaign'
    },
    member_subscription_data_custom_value: {
        id: `${scope}.campaign`,
        defaultMessage: 'Campaign'
    },
    campaignMemberData: {
        id: `${scope}.campaignMemberData`,
        defaultMessage: 'Campaign Member Data'
    },
    document_download: {
        id: `${scope}.document_download`,
        defaultMessage: 'Document Download'
    },
    campaign_form: {
        id: `${scope}.campaign_form`,
        defaultMessage: 'Campaign Form'
    },
    application_amount: {
        id: `${scope}.amount`,
        defaultMessage: 'Application amount'
    },
    closedDate: {
        id: `${scope}.closedDate`,
        defaultMessage: 'Closed Date'
    },
    preview: {
        id: `${scope}.preview`,
        defaultMessage: 'Preview'
    },
    alreadyExist: {
        id: `${scope}.alreadyExist`,
        defaultMessage: 'already exist.'
    },
    data: {
        id: `${scope}.data`,
        defaultMessage: 'Data'
    },
    notification: {
        id: `${scope}.notification`,
        defaultMessage: 'Notification'
    },
    message: {
        id: `${scope}.message`,
        defaultMessage: 'Message'
    },
    thumbnailUrl: {
        id: `${scope}.thumbnailUrl`,
        defaultMessage: 'Thumbnail url'
    },
    launchingUrl: {
        id: `${scope}.launchingUrl`,
        defaultMessage: 'Launching url'
    },
    sendNow: {
        id: `${scope}.sendNow`,
        defaultMessage: 'Send Now'
    },
    login: {
        id: `${scope}.login`,
        defaultMessage: 'Login'
    },
    character_full: {
        id: `${scope}.character_full`,
        defaultMessage: 'Full Text'
    },
    character_remain: {
        id: `${scope}.character_remain`,
        defaultMessage: 'Remaining Text'
    },
    remain_access: {
      id: `${scope}.remain_access`,
      defaultMessage: 'Remaining Access'
    },
    article_id: {
        id: `${scope}.article_id`,
        defaultMessage: 'Article ID'
    },
    current_page: {
        id: `${scope}.current_page`,
        defaultMessage: 'Current Page'
    },
    enabled: {
        id: `${scope}.enabled`,
        defaultMessage: 'Enabled'
    },
    cancelAllSubscription: {
        id: `${scope}.cancelAllSubscription`,
        defaultMessage: 'All subscriptions will be cancelled'
    },
    cancelAllSubscriptionConfirmText: {
        id: `${scope}.cancelAllSubscriptionConfirmText`,
        defaultMessage: 'When executed, the subscription will be canceled immediately even if the subscription period remains, and the user will no longer be a subscription user.'
    },
    ok: {
        id: `${scope}.ok`,
        defaultMessage: 'Okay'
    },
    hidden: {
        id: `${scope}.hidden`,
        defaultMessage: 'Hidden'
    },
    startDate: {
        id: `${scope}.startDate`,
        defaultMessage: 'Start Date'
    },
    endDate: {
        id: `${scope}.endDate`,
        defaultMessage: 'End Date'
    },
    coupon: {
        id: `${scope}.coupon`,
        defaultMessage: 'Coupon'
    },
    couponCode: {
        id: `${scope}.couponCode`,
        defaultMessage: 'Coupon Code'
    },
    jypCurrency: {
        id: `${scope}.jypCurrency`,
        defaultMessage: 'JYP'
    },
    period: {
        id: `${scope}.period`,
        defaultMessage: 'Period'
    },
    duration: {
        id: `${scope}.duration`,
        defaultMessage: 'Duration'
    },
    once: {
        id: `${scope}.once`,
        defaultMessage: 'Once'
    },
    forever: {
        id: `${scope}.forever`,
        defaultMessage: 'Forever'
    },
    monthly: {
        id: `${scope}.monthly`,
        defaultMessage: 'Monthly'
    },
    numberOfMonths: {
        id: `${scope}.numberOfMonths`,
        defaultMessage: 'Number of months'
    },
    amountOff: {
        id: `${scope}.amountOff`,
        defaultMessage: 'Amount Off'
    },
    issuedReceipt: {
        id: `${scope}.issuedReceipt`,
        defaultMessage: 'Issued Receipt'
    },
    articleDonation: {
        id: `${scope}.articleDonation`,
        defaultMessage: 'Article Donation'
    },
    issuedDate: {
        id: `${scope}.issuedDate`,
        defaultMessage: 'Issued Date'
    },
    invoiceNumber: {
        id: `${scope}.invoiceNumber`,
        defaultMessage: 'Invoice Number'
    },
    receiptFrom: {
        id: `${scope}.receiptFrom`,
        defaultMessage: 'Receipt From'
    },
    registeredAt: {
        id: `${scope}.registeredAt`,
        defaultMessage: 'Registerd at'
    },
    sortBy: {
        id: `${scope}.sortBy`,
        defaultMessage: 'Sort by'
    },
    filterBy: {
        id: `${scope}.filterBy`,
        defaultMessage: 'Filter by'
    },
    cxenseAnalytics: {
        id: `${scope}.cxenseAnalytics`,
        defaultMessage: 'Cxense Analytics'
    },
    dateRange: {
        id: `${scope}.dateRange`,
        defaultMessage: 'Date Range'
    },
    memberName: {
        id: `${scope}.memberName`,
        defaultMessage: 'Member name'
    },
    subscriptionPlan: {
        id: `${scope}.subscriptionPlan`,
        defaultMessage: 'Subscription plan'
    },
    gotoDetail: {
        id: `${scope}.gotoDetail`,
        defaultMessage: 'Go to detail'
    },
    unlimited: {
        id: `${scope}.unlimited`,
        defaultMessage: 'Unlimited'
    },
    anonymous: {
        id: `${scope}.anonymous`,
        defaultMessage: 'Anonymous'
    },
    isEmail: {
        id: `${scope}.isEmail`,
        defaultMessage: 'Is Email?'
    },
    isName: {
        id: `${scope}.isName`,
        defaultMessage: 'Is Name?'
    },
    exporting: {
        id: `${scope}.exporting`,
        defaultMessage: 'Exporting'
    },
    importing: {
        id: `${scope}.importing`,
        defaultMessage: 'Importing'
    },
    importCSV: {
        id: `${scope}.importCSV`,
        defaultMessage: 'Import CSV'
    },
    sampleCSV: {
        id: `${scope}.sampleCSV`,
        defaultMessage: 'Sample CSV'
    },
    importMember: {
        id: `${scope}.importMember`,
        defaultMessage: 'Import Member'
    },
    sampleExcel: {
        id: `${scope}.sampleExcel`,
        defaultMessage: 'Sample Excel'
    },
    startImport: {
        id: `${scope}.startImport`,
        defaultMessage: 'Start Import'
    },
    rowNub: {
        id: `${scope}.rowNub`,
        defaultMessage: 'Row {nub}'
    },
    exportZip: {
        id: `${scope}.exportZip`,
        defaultMessage: 'Export Zip'
    },
    memberCSVOutput: {
        id: `${scope}.memberCSVOutput`,
        defaultMessage: 'member CSV Output'
    },
    csvOutput: {
        id: `${scope}.csvOutput`,
        defaultMessage: 'CSV Output'
    },
    customCreatableInvalid: {
        id: `${scope}.customCreatable.invalid`,
        defaultMessage: 'Enter value is not valid. Please try again.'
    },
    customCreatablePlaceholder: {
        id: `${scope}.customCreatable.placeholder`,
        defaultMessage: 'Type something and press tab or enter... '
    },
    allCustomFields: {
        id: `${scope}.allCustomFields`,
        defaultMessage: 'All Custom Fields'
    },
    profileCustomFields: {
        id: `${scope}.profileCustomFields`,
        defaultMessage: 'Profile Custom Fields'
    },
    registerCustomFields: {
        id: `${scope}.registerCustomFields`,
        defaultMessage: 'Register Custom Fields'
    },
    noOptionsMessage: {
        id: `${scope}.noOptionsMessage`,
        defaultMessage: 'No options'
    },
    notificationTitle: {
        id: `${scope}.notificationTitle`,
        defaultMessage: 'Notification Title'
    },
    copy: {
        id: `${scope}.copy`,
        defaultMessage: 'Copy'
    },
    clone: {
        id: `${scope}.clone`,
        defaultMessage: 'Clone'
    },
    copyForArticleDonation: {
        id: `${scope}.copyForArticleDonation`,
        defaultMessage: 'Copy for article donation'
    },
    campaignFormCategory: {
        id: `${scope}.campaignFormCategory`,
        defaultMessage: 'Campaign Form Category'
    },
    showOnMyPageHistory: {
        id: `${scope}.showOnMyPageHistory`,
        defaultMessage: 'Show On My Page History'
    },
    useELearning: {
        id: `${scope}.useELearning`,
        defaultMessage: 'Use E Learning'
    },
    showQuestionnaire: {
        id: `${scope}.showQuestionnaire`,
        defaultMessage: 'Show Questionnaire'
    },
    cancelTitle: {
        id: `${scope}.cancelTitle`,
        defineMessages: 'Are you sure to cancel?',
    },
    recoverTitle: {
        id: `${scope}.recoverTitle`,
        defineMessages: 'Are you sure to recover cancel?',
    },
    cancelConfirm: {
        id: `${scope}.cancelConfirm`,
        defineMessages: 'Yes, cancel it!',
    },
    recoverConfirm: {
        id: `${scope}.recoverConfirm`,
        defineMessages: 'Yes, recover it!',
    },
    category: {
        id: `${scope}.category`,
        defineMessages: 'Category',
    },
    cuenoteCustomFields: {
        id: `${scope}.cuenoteCustomFields`,
        defaultMessage: 'Cuenote Custom Fields'
    },
    exportingCSV: {
        id: `${scope}.exportingCSV`,
        defaultMessage: 'Exporting CSV'
    },
    exportingExcel: {
        id: `${scope}.exportingExcel`,
        defaultMessage: 'Exporting Excel'
    },
    loadMore: {
        id: `${scope}.loadMore`,
        defaultMessage: 'Load More'
    },
    linkFieldSlug: {
        id: `${scope}.linkFieldSlug`,
        defaultMessage: 'Parent Field'
    },
    corporateMember: {
        id: `${scope}.corporateMember`,
        defaultMessage: 'Corporate Member'
    },

    elearning: {
        id: `${scope}.elearning`,
        defaultMessage: 'E-Learning'
    },
    questionnaire: {
        id: `${scope}.questionnaire`,
        defaultMessage: 'Questionnaire'
    },
    importQuestion: {
        id: `${scope}.importQuestion`,
        defaultMessage: 'Import Question'
    },
    question: {
        id: `${scope}.question`,
        defaultMessage: 'Question'
    },
    enterQuestion: {
        id: `${scope}.enterQuestion`,
        defaultMessage: "Enter Question"
    },
    enterDescription: {
        id: `${scope}.enterDescription`,
        defaultMessage: "Enter Description"
    },
    optionType: {
        id: `${scope}.optionType`,
        defaultMessage: "Option Type"
    },
    points: {
        id: `${scope}.points`,
        defaultMessage: "Points"
    },
    test: {
        id: `${scope}.test`,
        defaultMessage: 'Test'
    },
    import: {
        id: `${scope}.test`,
        defaultMessage: 'Import'
    },
    course: {
        id: `${scope}.course`,
        defaultMessage: "Course"
    },
    lesson: {
        id: `${scope}.lesson`,
        defaultMessage: "Lesson"
    },
    categoryName: {
        id: `${scope}.categoryName`,
        defaultMessage: "Category Name"
    },
    lessonLevels: {
        id: `${scope}.lessonLevels`,
        defaultMessage: "Lesson Levels"
    },
    selectCourses: {
        id: `${scope}.selectCourses`,
        defaultMessage: "─ Select courses ─"
    },
    selectLevel: {
        id: `${scope}.selectLevel`,
        defaultMessage: "─ Select level ─"
    },
    selectTest: {
        id: `${scope}.selectTest`,
        defaultMessage: "─ Select test ─"
    },
    selectQuestionnaires: {
        id: `${scope}.selectQuestionnaires`,
        defaultMessage: "─ Select questionnaires ─"
    },
    materialDownload: {
        id: `${scope}.materialDownload`,
        defaultMessage: 'Material Download'
    },
    uploadFile: {
        id: `${scope}.uploadFile`,
        defaultMessage: 'Upload File'
    },
    uploadUrl: {
        id: `${scope}.uploadUrl`,
        defaultMessage: 'Upload URL'
    },
});
