import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import SiteReducer from './sites';
import AuthReducer from './auth';
import LocaleReducer from './locale';
import AnnouncementReducer from './announcements';
import CategoryReducer from './categories';
import ErrorReducer from './errors';
import MemberReducer from './members';
import MemberTagReducer from './memberTag';
import ProductReducer from './products';
import ApplicationReducer from './application';
import CustomFieldReducer from './customFields';
import TagReducer from './tags';
import UserReducer from './users';
import MessageReducer from './messages';
import SaleReducer from './sales';
import ScenarioReducer from './scenario';
import TemplateReducer from './templates';
import SegmentReducer from './segments';
import SubscriptionDataReducer from './subscriptionsData';
import MemberSubscriptionDataReducer from './memberSubscriptionsData';
import NotificationReducer from './notifications';
import CouponReducer from './coupon';
import CouponCodeReducer from './couponCode';
import SalePaymentReducer from './salePayments';
import CxensesReducer from './cxenses';
import SubscriptionCategoryReducer from './SubscriptionCategory';
import CouponCodeUsageReducer from './couponCodeUsage';
import CorporateMemberReducer from './corporateMember';
import CorporateMemberTagReducer from './corporateMemberTag';
import CourseReducer from './course';
import LessonReducer from './lesson';
import LessonLevelReducer from './lessonLevel';
import QuizReducer from './quiz';
import QuestionnaireReducer from './questionnaire';

const rootReducer = (history, injectedReducers = {}) => combineReducers({
  announcements: AnnouncementReducer,
  application: ApplicationReducer,
  auth: AuthReducer,
  categories: CategoryReducer,
  coupon: CouponReducer,
  couponCode: CouponCodeReducer,
  customFields: CustomFieldReducer,
  errors: ErrorReducer,
  locale: LocaleReducer,
  members: MemberReducer,
  memberTags: MemberTagReducer,
  messages: MessageReducer,
  products: ProductReducer,
  sales: SaleReducer,
  router: connectRouter(history),
  scenario: ScenarioReducer,
  sites: SiteReducer,
  tags: TagReducer,
  users: UserReducer,
  templates: TemplateReducer,
  segments: SegmentReducer,
  subscriptionsData: SubscriptionDataReducer,
  memberSubscriptionsData: MemberSubscriptionDataReducer,
  salePayments: SalePaymentReducer,
  notifications: NotificationReducer,
  cxenses: CxensesReducer,
  subscriptionCategory: SubscriptionCategoryReducer,
  couponCodeUsage: CouponCodeUsageReducer,
  corporateMembers: CorporateMemberReducer,
  corporateMemberTags: CorporateMemberTagReducer,
  courses: CourseReducer,
  lessons: LessonReducer,
  lessonLevels: LessonLevelReducer,
  quizzes: QuizReducer,
  questionnaires: QuestionnaireReducer,
  ...injectedReducers,
});

export default rootReducer;
