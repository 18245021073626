import Axios from '@/plugins/axios';
import Cookie from 'js-cookie'
import history from '@/utils/history';

class BaseProxy {
  /**
   * The constructor of the BaseProxy.
   *
   * @param {string} endpoint   The endpoint being used.
   * @param {Object} parameters The parameters for the request.
   */
  constructor (endpoint, parameters = {}) {
    this.endpoint = endpoint;
    this.parameters = parameters;
  }

  /**
   * Method used to set the query parameters.
   *
   * @param {Object} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameters (parameters) {
    Object.keys(parameters).forEach(key => {
      this.parameters[key] = parameters[key];
    });

    return this;
  }

  /**
   * Method used to set a single parameter.
   *
   * @param {string} parameter The given parameter.
   * @param {*} value The value to be set.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  setParameter (parameter, value) {
    this.parameters[parameter] = value;

    return this;
  }

  /**
   * Method used to remove all the parameters.
   *
   * @param {Array} parameters The given parameters.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameters (parameters) {
    parameters.forEach(parameter => {
      delete this.parameters[parameter];
    });

    return this;
  }

  /**
   * Method used to remove a single parameter.
   *
   * @param {string} parameter The given parameter.
   *
   * @returns {BaseProxy} The instance of the proxy.
   */
  removeParameter (parameter) {
    delete this.parameters[parameter];

    return this;
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {Object|null} data        The data that's being send to the API.
   *
   * @returns {Promise} The result in a promise.
   */
  submit (requestType, url, data = null) {
    this.setParameter('currentSite', Cookie.get('current_site_id') || (this.parameters['currentSite'] ? this.parameters['currentSite'] : null));
    return new Promise((resolve, reject) => {
      Axios[requestType](url + this.getParameterString(), data)
        .then(response => {
          resolve(response.data);
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject(new Error('Error while connecting to api'));
          }
        });
    });
  }

  /**
   * Method used to fetch all items from the API.
   *
   * @returns {Promise} The result in a promise.
   */
  all () {
    return this.submit('get', `/${this.endpoint}`);
  }

  /**
   * Method used to fetch a single item from the API.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  find (id) {
    return this.submit('get', `/${this.endpoint}/${id}`);
  }

  /**
   * Method used to create an item.
   *
   * @param {Object} item The given item.
   *
   * @param formData The FormData will send to the server
   * @returns {Promise} The result in a promise.
   */
  create (item, formData = false) {
    if (formData) item = this.objectToFormData(item);
    return this.submit('post', `/${this.endpoint}`, item);
  }

  /**
   * Method used to update a given item.
   *
   * @param {int} id The given identifier.
   * @param {Object} item The given item.
   *
   * @param formData The FormData will be send to server
   * @returns {Promise} The result in a promise.
   */
  update (id, item, formData = false) {
    if (formData) item = this.objectToFormData(item);
    return this.submit('put', `/${this.endpoint}/${id}`, item);
  }

  /**
   * Method used to destroy a given item.
   *
   * @param {int} id The given identifier.
   *
   * @returns {Promise} The result in a promise.
   */
  destroy (id) {
    return this.submit('delete', `/${this.endpoint}/${id}`);
  }

  download(url, data, fileName) {
    this.setParameter('currentSite', Cookie.get('current_site_id') || (this.parameters['currentSite'] ? this.parameters['currentSite'] : null));
    return new Promise((resolve, reject) => {
      Axios['post'](url + this.getParameterString(), { data } , {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => {
          var blob = response.data;
          var link = document.createElement('a');
          link.href= window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject(new Error('Error while connecting to api'));
          }
        });
    });
  }

  /**
   * Method used to transform a parameters object to a parameters string.
   *
   * @returns {string} The parameter string.
   */
  getParameterString () {
    const keys = Object.keys(this.parameters);

    const parameterStrings = keys
      .filter(key => !!this.parameters[key])
      .map(key => `${key}=${encodeURIComponent(this.parameters[key])}`);

    return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`;
  }

  /**
   * Convert object to formData
   * @param obj
   * @param form
   * @param namespace
   * @returns {*|FormData}
   */
  objectToFormData (obj, form, namespace) {
    const fd = form || new FormData();
    let property = null;
    for (property in obj) {
      if (!obj.hasOwnProperty(property)) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (obj[property] === null) {
        fd.append(formKey, '');
        continue;
      }
      if (typeof obj[property] === 'boolean') {
        fd.append(formKey, obj[property] ? '1' : '0');
        continue;
      }
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
        continue;
      }
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        this.objectToFormData(obj[property], fd, formKey);
        continue;
      }
      fd.append(formKey, obj[property]);
    }
    return fd;
  }

  setBrowserUrlFilter(parameters = {}) {
    if (!parameters) {
      return;
    }
    const keys = Object.keys(parameters);
    const parameterStrings = keys
      .filter(key => !!parameters[key])
      .map(key => {
        const value = encodeURIComponent(parameters[key]);
        return `${key}=${value}`;
      });
    
    const urlParts = window.location.href.split('?');
    let searchParams = new URLSearchParams(urlParts[1]);
    if(this.parameters?.page) {
      searchParams.set("page", this.parameters?.page);
    } else {
      searchParams.delete("page");
    }

    if(this.parameters?.limit) {
      searchParams.set("limit", this.parameters?.limit);
    } else {
      searchParams.delete("limit");
    }
    const existingParams = urlParts.length > 1 ? searchParams.toString().split('&') : [];
    const mergedParams = existingParams.filter(param => {
      const paramName = param.split('=')[0];
      return !keys.includes(paramName);
    }).concat(parameterStrings);
    
    const finalUrlParams = mergedParams.join('&');
    const finalUrl = finalUrlParams.toString() ? '?' + finalUrlParams.toString() : '';
    Cookie.set("MMS-URL-HISTORY", urlParts[0]+finalUrl)
    history.replace({
      search: finalUrl
    });
  }

  goBackPreviousRoute(route) {
    const previousUrlParams = Cookie.get("MMS-URL-HISTORY");
    Cookie.remove("MMS-URL-HISTORY");
  
    if (previousUrlParams) {
      const url = previousUrlParams.split('/');
      const path = url.length > 3 ? url[3].split('?') : [];
  
      if (path[0] === route && path.length > 1) {
        history.push(`/${route}?${path[1]}`);
      } else {
        history.push(`/${route}`);
      }
    } else {
      history.push(`/${route}`);
    }
  }
}

export default BaseProxy;
