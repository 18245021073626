import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router/immutable';

const composeEnhancers = composeWithDevTools({ trace: true }) || compose;

// const loggerMiddleware = createLogger();

export const history = '@/utils/history';

export default function configureStore (preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware,
        routerMiddleware(history),
      ),
    ),
  );
  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createRootReducer < history);
    });
  }
  return store;
}

