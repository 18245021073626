/*
 *
 * LanguageProvider reducer
 *
 */

import { CHANGE_LOCALE } from '@/actions/locale/types';

import I18n from '@/utils/I18n';

export const initialState = {
    locale: I18n.getCurrentLocale(),
};

const languageProviderReducer = (state = initialState, action) => {
    if (action.type === CHANGE_LOCALE) {
        I18n.setLocale(action.locale);
        return Object.assign({}, state, {
            locale: action.locale
        })
    }
    return state
}

export default languageProviderReducer;
