/* ============
 * User Transformer
 * ============
 *
 * The transformer for a user.
 */

import Transformer from './BaseTransformer'
import Url from '@/utils/Url'

class UserTransformer extends Transformer {

  /**
   * Method used to transform a fetched user.
   */
  static fetch (user) {
    return {
      id: user.id,
      name: user.name,
      display_name: user.display_name,
      email: user.email,
      role: this.Ucfirst(user.role),
      sites: user.sites,
      avatar: user.avatar ? (Url.isValid(user.avatar) ? user.avatar : `${process.env.REACT_APP_API_LOCATION}/users/avatar/${user.id}/${user.avatar}`) : null,
      current_password: "",
      password: "", 
      password_confirmation: ""
    }
  }

  /**
   * Method used to transform a send user.
   *
   * @returns {Object} The transformed user.
   */
  static send (user) {
    return {
      id: user.id,
      name: user.name,
      display_name: user.display_name,
      email: user.email,
      role: this.Ucfirst(user.role),
      avatar: user.avatar,
      password: user.password
    }
  }

  /**
   * Convert string to first uppercase
   * @param s
   * @returns {string}
   * @constructor
   */
  static Ucfirst (s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
  }
}

export default UserTransformer
