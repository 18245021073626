import { combineReducers } from 'redux'
import { ALL } from '@/actions/sites/types'

const initialState = {
  all: [],
  pagination: {
    count: 0,
    current_page: 1,
    per_page: 0,
    total: 0,
    total_pages: 0,
    from: 0,
    to: 0
  },
}

/**
 * Get state for all sites
 * @param state
 * @param action
 * @returns {{} & {all: Array, pagination: {per_page: number, total: number, count: number, total_pages: number, current_page: number}} & {error: *}}
 */
function sites (state = initialState, action) {
  if (action.type === ALL) {
    let from = (action.pagination.current_page - 1) * action.pagination.per_page + 1
    let to = from + action.pagination.count - 1
    return Object.assign({}, state, {
      all: action.all,
      pagination: {
        ...action.pagination,
        from,
        to
      }
    })
  }
  return state
}

const SiteReducer = combineReducers({ sites })

export default SiteReducer
