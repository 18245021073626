import { combineReducers } from "redux";
import { ALL } from "@/actions/scenario/types";


const initialState = {
    all: [],
    pagination: {
        count: 0,
        current_page: 1,
        per_page: 0,
        total: 0,
        total_pages: 0,
        from: 0,
        to: 0
    }
};

function scenario (state = initialState, action) {
    if (action.type === ALL) {
        let from = (action.pagination.current_page - 1) * action.pagination.per_page + 1
        let to = from + action.pagination.count - 1
        return Object.assign({}, state, {
            all: action.all,
            pagination: {
                ...action.pagination,
                from,
                to
            }
        });
    }
    return state;
}

const ScenarioReducer = combineReducers({ scenario });

export default ScenarioReducer;
