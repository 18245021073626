export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_FAILED = 'AUTH/LOGIN_FAILED';
export const CHECK = 'AUTH/CHECK';
export const LOGGED_OUT = 'AUTH/LOGGED_OUT';
export const FETCH_USER = 'AUTH/FETCH_USER';
export const PROFILE_UPDATED = 'AUTH/PROFILE_UPDATED';

export default {
  LOGIN,
  CHECK,
  FETCH_USER,
  LOGGED_OUT,
  LOGIN_FAILED,
  PROFILE_UPDATED
}
