import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import Immutable from 'immutable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Import Language Provider
import LanguageProvider from '@/containers/LanguageProvider';
// Import i18n messages
import { translationMessages } from '@/plugins/i18n';
import configureStore, { history } from './store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('dotenv').config();

const initialState = Immutable.Map();
const store = configureStore(initialState);

const MOUNT_NODE = document.getElementById('root');

// Changed the render
const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <App history={history}/>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};
// end changed the render

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept(['@/plugins/i18n', './App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/ja.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
