/*
 * Users Messages
 *
 * This contains all the text for the User component.
 */
import { defineMessages } from "react-intl"

export const scope = "mms.user"

export default defineMessages({
    selectRole: {
        id: `${scope}.selectRole`,
        defaultMessage: '─ Select Role ─'
    },
    enterUserNames: {
        id: `${scope}.enterUserNames`,
        defaultMessage: 'Enter user name'
    },
    enterUserDisplayName: {
        id: `${scope}.enterUserDisplayName`,
        defaultMessage: 'Enter user display name'
    },
    enterUserEmail: {
        id: `${scope}.enterUserEmail`,
        defaultMessage: 'Enter user email'
    },
    currentPassword: {
        id: `${scope}.currentPassword`,
        defaultMessage: 'Current password'
    },
    enterUserCurrentPassword: {
        id: `${scope}.enterUserCurrentPassword`,
        defaultMessage: 'Enter user current password'
    },
    enterUserPassword: {
        id: `${scope}.enterUserPassword`,
        defaultMessage: 'Enter user password'
    },
    passwordConfirmation: {
        id: `${scope}.passwordConfirmation`,
        defaultMessage: 'Password confirmation'
    },
    enterUserPasswordConfirmation: {
        id: `${scope}.enterUserPasswordConfirmation`,
        defaultMessage: 'Enter user password confirmation'
    },
    admin: {
        id: `${scope}.admin`,
        defaultMessage: 'Admin'
    },
    manager: {
        id: `${scope}.manager`,
        defaultMessage: 'Manager'
    },
    successCreate: {
        id: `${scope}.successCreate`,
        defaultMessage: 'User has been created.'
    },
    failCreate: {
        id: `${scope}.failCreate`,
        defaultMessage: 'Failed to create user.'
    },
    successUpdate: {
        id: `${scope}.successUpdate`,
        defaultMessage: 'User has been updated.'
    },
    failUpdate: {
        id: `${scope}.failUpdate`,
        defaultMessage: 'Failed to update user.'
    },
    successDelete: {
        id: `${scope}.successDelete`,
        defaultMessage: 'User has been deleted.'
    },
    failDelete: {
        id: `${scope}.failDelete`,
        defaultMessage: 'Failed to delete user.'
    },
})
