export const ALL = 'MESSAGE/ALL'
export const UPDATED = 'MESSAGE/UPDATED'
export const CREATED = 'MESSAGE/CREATED'
export const DELETED = 'MESSAGE/DELETED'
export const REPLIES = 'MESSAGE/REPLIES'
export const BADGE = 'MESSAGE/BADGE'

export default {
  ALL,
  UPDATED,
  CREATED,
  REPLIES,
  DELETED,
  BADGE
}
