import { combineReducers } from 'redux';
import { CLEAR, FILL, FLUSH } from '@/actions/errors/types';
import Validator from '@/utils/Validator'

const initialState = {
  error: {},
  message: ''
};

/**
 * Function reducer errors
 * @param state
 * @param action
 * @returns {({} & {error: {}, message: string} & {error: *, message: *})|{error: {}, message: string}|({} & {error: {}, message: string})|({} & {error: {}, message: string} & {error, message: string})}
 */
function errors (state = initialState, action) {
  if (action.type === FILL) {
    return Object.assign({}, state, {
      error: action.error,
      message: action.message
    });
  }
  if (action.type === CLEAR) {
    const errors = {}
    const field = action.field
    if (field) {
      Object.keys(state.error).forEach(key => {
        if (key !== field) {
          errors[key] = state.error[key]
        }
      })
    }
    Validator.fill(errors)
    return Object.assign({}, state, {
      error: errors,
      message: ''
    });
  }
  if (action.type === FLUSH) {
    Validator.flush()
    return Object.assign({}, state, {
      error: {},
      message: ''
    });
  }
  return state;
}

const ErrorReducer = combineReducers({ errors });

export default ErrorReducer;
