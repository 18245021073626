import { IS_CREATED, IS_LOADING } from './types'

export function IsLoadingMutation (isLoading = false) {
  return {
    type: IS_LOADING,
    isLoading
  }
}

export function IsCreatedMutation (isCreated = false) {
  return {
    type: IS_CREATED,
    isCreated
  }
}
