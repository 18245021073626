import { combineReducers } from "redux";
import { ALL } from "@/actions/users/types";

const initialState = {
  all: [],
  pagination: {
    count: 0,
    current_page: 1,
    per_page: 0,
    total: 0,
    total_pages: 0
  }
};

/**
 * Get state for all users
 * @param state
 * @param action
 * @returns {{all: Array, pagination: {per_page: number, total: number, count: number, total_pages: number, current_page: number}}}
 */
function users (state = initialState, action) {
  if (action.type === ALL) {
    return Object.assign({}, state, {
      all: action.all,
      pagination: action.pagination
    });
  }
  return state;
}

const UserReducer = combineReducers({ users });

export default UserReducer;
