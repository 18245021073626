import { IS_CREATED, IS_LOADING } from '@/actions/application/types'
import { combineReducers } from 'redux'

const initialState = {
  isLoading: false,
  isCreated: false
}

/**
 * Application reducer
 * @param state
 * @param action
 * @returns {{} & {isLoading: boolean} & {all: *, pagination: *}}
 */
function application (state = initialState, action) {
  if (action.type === IS_LOADING) {
    return Object.assign({}, state, {
      isLoading: action.isLoading
    })
  }
  if (action.type === IS_CREATED) {
    return Object.assign({}, state, {
      isCreated: action.isCreated
    })
  }
  return state
}

const ApplicationReducer = combineReducers({ application })

export default ApplicationReducer
