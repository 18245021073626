import history from './history';

class Url {

    static isValid(string) {
        try {
            new URL(string);
            return true;
        } catch (e) {
            return false;
        }
    }

    static addhttp(url) {
        // eslint-disable-next-line
        if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = "http://" + url;
        }
        return url;
    }

    static getParam(key) {
        if (!key) {
            return;
        }

        return new URLSearchParams(history.location.search).get(key) || '';;
    }

}

export default Url;
