import Proxy from '@/proxies/AuthProxy'
import history from '@/utils/history'
import Swal from 'sweetalert2'
import { CheckMutation, LoginMutation, LogoutMutation, ProfileUpdatedMutation } from './mutations'
import { FillMutation } from '@/actions/errors/mutations'
import { IsLoadingMutation } from '../application/mutations'
import generalMsg from '@/messages/users';
import I18n from '@/utils/I18n';

const proxy = new Proxy()

/**
 * Dispatch user login
 * @param item
 * @returns {Function}
 */
const loginProxy = (item = {}) => dispatch => {
    dispatch(IsLoadingMutation(true))
    proxy.login(item)
        .then(response => {
            dispatch(LoginMutation(response))
            dispatch(IsLoadingMutation(false))
            if (item.from && item.from.pathname) {
                return history.push(`${item.from.pathname}`)
            }
            return history.push('/dashboard')
        })
        .catch(error => {
            dispatch(FillMutation(error))
            dispatch(IsLoadingMutation(false))
        })
}

/**
 * Dispatch user logout
 * @returns {Function}
 */
const logoutProxy = () => dispatch => {
    proxy.logout()
        .then(async response => {
            await dispatch(LogoutMutation())
            await Swal.fire({
                position: 'top-end',
                type: 'success',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
            })
            history.push('/login')
        })
        .catch(async error => {
            await Swal.fire({
                position: 'top-end',
                type: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            })
        })
}

/**
 * Fetch current logged in user
 * @returns {Function}
 */
const fetchUser = () => dispatch => {
    proxy.user()
        .then(response => {
            dispatch(CheckMutation(response))
        })
        .catch(error => {
            console.log(error);
            if (error.code === 401) {
                dispatch(LogoutMutation())
            }
        })
}

/**
 * Edit Profile Proxy
 * @param item
 * @returns {Function}
 */
const editProfileProxy = (item = {}) => dispatch => {
    dispatch(IsLoadingMutation(true))
    proxy.profile(item)
        .then(async response => {
            await dispatch(IsLoadingMutation(false))
            await Swal.fire({
                position: 'top-end',
                type: 'success',
                title: response.message ? response.message : I18n.t(generalMsg.successUpdate),
                showConfirmButton: false,
                timer: 1500
            })
            await dispatch(ProfileUpdatedMutation(response.data))
            await history.push('/dashboard')
        })
        .catch(async error => {
            dispatch(IsLoadingMutation(false))
            dispatch(FillMutation(error))
            await Swal.fire({
                position: 'top-end',
                type: 'error',
                title: error.message ? error.message : I18n.t(generalMsg.failUpdate),
                showConfirmButton: false,
                timer: 1500
            })
        })
}

/**
 * Export login function
 * @param payload
 * @param redirect
 * @returns {function(*, *): *}
 */
export function login(payload = {}, redirect = null) {
    return (dispatch, getState) => {
        Object.assign(payload, redirect)
        return dispatch(loginProxy(payload))
    }
}

/**
 * Export logout function
 * @param payload
 * @returns {function(*, *): *}
 */
export function logout(payload = {}) {
    return (dispatch, getState) => {
        return dispatch(logoutProxy())
    }
}

/**
 * Export check user function
 * @returns {function(*, *): *}
 */
export function check() {
    return (dispatch, getState) => {
        return dispatch(CheckMutation())
    }
}

/**
 * Export fetch current user data function
 * @returns {function(*, *): *}
 */
export function fetchAuth() {
    return (dispatch, getState) => {
        return dispatch(fetchUser())
    }
}

/**
 * Allowed user to edit own data
 * @param item
 * @returns {function(*, *): *}
 */
export function editProfile(item = {}) {
    return (dispatch, getState) => {
        return dispatch(editProfileProxy(item))
    }
}
