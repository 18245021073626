class Validator {

  constructor () {
    this.errors = {}
  }

  has = key => this.errors[key] !== undefined

  first = key => {
    if (this.has(key)) {
      return this.errors[key][0]
    }
  }

  get = key => {
    if (this.has(key)) {
      return this.errors[key]
    }
  }

  all = () => this.errors

  fill = values => {
    this.errors = values
  }

  flush = () => {
    this.errors = {}
  }

  clear = field => {
    const errors = {}
    if (field) {
      Object.keys(this.errors).forEach(key => {
        if (key !== field) {
          errors[key] = this.errors[key]
        }
      })
    }
    this.errors = errors;
  }

  /**
   * Clear errors on keydown.
   *
   * @param {KeyboardEvent} event
   */
  onKeydown = event => {
    let validator = new Validator()
    if (event.target.name) {
      validator.clear(event.target.name)
    }
  }
}

export default new Validator()
