import Cookie from 'js-cookie';
import { translationMessages, DEFAULT_LOCALE } from '@/plugins/i18n';
import jsonEditorEN from 'react-json-editor-ajrm/locale/en';
import jsonEditorJA from 'react-json-editor-ajrm/locale/jpn';
import generalMsg from '@/messages/general';

/**
 * @author Phirum Seng
 * @since 2019/11/21
 */
class I18n {

    KEY = 'X-MMS-Locale';

    _defDict = translationMessages[DEFAULT_LOCALE];

    _jsonEditorLocales = {
        'en': jsonEditorEN,
        'ja': jsonEditorJA
    };

    getCurrentLocale() {
        let currentLocale = Cookie.get(this.KEY) || DEFAULT_LOCALE;
        this.updateHtmlLang(currentLocale);
        return currentLocale;
    }

    setLocale(locale) {
        Cookie.set(this.KEY, locale);
        this.updateHtmlLang(locale);
    }

    updateHtmlLang(locale) {
        document.querySelector("html").setAttribute('lang', locale || DEFAULT_LOCALE);
    }

    t(message, values) {
        if (!message || !message.id) {
            return '';
        }
        let dict = translationMessages[this.getCurrentLocale()];
        if (!dict) {
            return message.defaultMessage || '';
        }
        let res = dict[message.id] || this._defDict[message.id];
        if (values) {
            res = Object.keys(values).reduce((text, k) => text.replace(new RegExp(`{${k}}`, "g"), values[k]), res);
        }
        return res || (message.defaultMessage || '');
    }

    getJsonEditorLocale() {
        return this._jsonEditorLocales[this.getCurrentLocale()];
    }

    getMomentCalendar() {
        return {
            lastDay: this.t(generalMsg.calendarLastDay),
            sameDay: this.t(generalMsg.calendarSameDay),
            nextDay: this.t(generalMsg.calendarNextDay),
            lastWeek: this.t(generalMsg.calendarLastWeek),
            nextWeek: this.t(generalMsg.calendarNextWeek),
            sameElse: this.t(generalMsg.calendarSameElse)
        }
    }
}

export default new I18n();