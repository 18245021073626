/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import jaLocaleData from 'react-intl/locale-data/ja';

import enTranslationMessages from '@/lang/en.json'
import jaTranslationMessages from '@/lang/ja.json'

addLocaleData([...enLocaleData, ...jaLocaleData]);

export const DEFAULT_LOCALE = 'ja';

// prettier-ignore
export const appLocales = [
    'en',
    'ja',
];

export const formatTranslationMessages = (locale, messages) => {
    const defaultFormattedMessages =
        locale !== DEFAULT_LOCALE
            ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
            : {};
    const flattenFormattedMessages = (formattedMessages, key) => {
        const formattedMessage =
            !messages[key] && locale !== DEFAULT_LOCALE
                ? defaultFormattedMessages[key]
                : messages[key];
        return Object.assign(formattedMessages, { [key]: formattedMessage });
    };
    return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
    en: formatTranslationMessages('en', enTranslationMessages),
    ja: formatTranslationMessages('ja', jaTranslationMessages),
};
