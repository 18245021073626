import Proxy from './BaseProxy';

class AuthProxy extends Proxy {
  /**
   * The constructor for the AuthProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor (parameters = {}) {
    super('auth', parameters);
  }

  /**
   * Method used to login.
   *
   * @param {Object} user The User object.
   *
   * @returns {Promise} The result in a promise.
   */
  login = user => {
    const data = {
      email: user.email,
      password: user.password,
      remember_me: user.remember_me,
    };
    return this.submit('post', `/${this.endpoint}/login`, data);
  }

  /**
   * Fetch User Logged in
   * @returns {Promise}
   */
  user = () => this.submit('get', `/${this.endpoint}/user`)

  /**
   * Logout use from current token
   * @returns {Promise}
   */
  logout = () => this.submit('post', `/${this.endpoint}/logout`)

  /**
   * Update user profile
   * @param item
   * @returns {Promise}
   */
  profile = item => this.submit('post', `/${this.endpoint}/profile`, item)
}

export default AuthProxy;
