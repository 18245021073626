import { CHECK, LOGGED_OUT, LOGIN, PROFILE_UPDATED } from './types'
import Cookie from 'js-cookie'
import { fetchAuth } from './index'
import UserTransformer from '@/transformers/UserTransformer'

/**
 * Get token and set user to reducer
 * @param {Object} response
 * @returns {{access_token: (*|null), authenticated: boolean, type: string, user: (AuthProxy.user|*|number|null)}}
 */
export function LoginMutation (response) {
  const auth = response.data
  return {
    type: LOGIN,
    authenticated: true,
    access_token: auth.access_token,
    user: UserTransformer.fetch(auth.user.data)
  }
}

/**
 * Check user authentication
 * @param response
 * @returns {{access_token: *, authenticated: boolean, type: string, user: *}}
 */
export function CheckMutation (response = {}) {
  return {
    type: CHECK,
    authenticated: !!Cookie.get('access_token'),
    access_token: Cookie.get('access_token'),
    user: response.data ? UserTransformer.fetch(response.data) : {
      role: 'Manager',
      name: '',
      email: '',
      avatar: ''
    }
  }
}

export function LogoutMutation () {
  return {
    type: LOGGED_OUT,
    access_token: null,
    user: null,
    authenticated: fetchAuth()
  }
}

export function ProfileUpdatedMutation (user) {
  return {
    type: PROFILE_UPDATED,
    user: UserTransformer.fetch(user)
  }
}
