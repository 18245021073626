import { combineReducers } from 'redux'
import { CHECK, LOGGED_OUT, LOGIN, LOGIN_FAILED, PROFILE_UPDATED } from '@/actions/auth/types'
import Cookie from 'js-cookie'
import Axios from '@/plugins/axios'

const initialState = {
  authenticated: false,
  access_token: null,
  user: {
    role: 'Manager',
    name: '',
    email: '',
    avatar: ''
  }
}

/**
 * Get state for all sites
 * @param state
 * @param action
 * @returns {{access_token: null, authenticated: boolean, user: null}}
 */
function auth (state = initialState, action) {
  if (action.type === LOGIN) {
    Cookie.set('access_token', action.access_token)
    Axios.defaults.headers.common['Authorization'] = `Bearer ${Cookie.get('access_token')}`
    Axios.defaults.headers.common['X-MMS-Service-Key'] = process.env.REACT_APP_XMMS_SERVICE_KEY
    return Object.assign({}, state, {
      authenticated: action.authenticated,
      access_token: action.access_token,
      user: action.user
    })
  }
  if (action.type === LOGGED_OUT) {
    Cookie.remove('access_token')
    Cookie.remove('current_site_id')
    return Object.assign({}, state, {
      authenticated: false,
      access_token: '',
      user: {}
    })
  }
  if (action.type === CHECK) {
    Axios.defaults.headers.common['Authorization'] = `Bearer ${Cookie.get('access_token')}`
    Axios.defaults.headers.common['X-MMS-Service-Key'] = process.env.REACT_APP_XMMS_SERVICE_KEY
    return Object.assign({}, state, {
      authenticated: action.authenticated,
      access_token: action.access_token,
      user: action.user
    })
  }
  if (action.type === LOGIN_FAILED) {
    return Object.assign({}, state, {
      error: action.error
    })
  }
  if (action.type === PROFILE_UPDATED) {
    return Object.assign({}, state, {
      user: action.user
    })
  }
  return state
}

const LoginReducer = combineReducers({ auth })

export default LoginReducer
