import { combineReducers } from 'redux'
import { ALL, UPDATED } from '@/actions/products/types'

const initialState = {
  all: [],
  pagination: {
    count: 0,
    current_page: 1,
    per_page: 0,
    total: 0,
    total_pages: 0,
  },
}

/**
 * Get state for all products
 * @param state
 * @param action
 * @returns {{all: Array, pagination: {per_page: number, total: number, count: number, total_pages: number, current_page: number}}}
 */
function products (state = initialState, action) {
  if (action.type === ALL) {
    let from = (action.pagination.current_page - 1) * action.pagination.per_page + 1
    let to = from + action.pagination.count - 1
    return Object.assign({}, state, {
      all: action.all,
      pagination: {
        ...action.pagination,
        from,
        to
      }
    })
  }
  if (action.type === UPDATED) {
    const product = action.product
    return Object.assign({}, state, {
      all: state.all.map(item => {
        if (item.id === product.id) {
          return product
        }
        return item
      })
    })
  }
  return state
}

const ProductReducer = combineReducers({ products })

export default ProductReducer
